import React, { useEffect } from 'react';
import tekening1 from '../assets/tekeningen/tekening1.jpg';
import tekening2 from '../assets/tekeningen/tekening2.jpg';
import tekening3 from '../assets/tekeningen/tekening3.jpg';
import tekening4 from '../assets/tekeningen/tekening4.jpg';
import tekening5 from '../assets/tekeningen/tekening5.jpg';
import tekening6 from '../assets/tekeningen/tekening6.jpg';
import tekening7 from '../assets/tekeningen/tekening7.jpg';
import tekening8 from '../assets/tekeningen/tekening8.jpg';
import tekening9 from '../assets/tekeningen/tekening9.jpg';
import tekening10 from '../assets/tekeningen/tekening10.jpg';
import '../styles/timeline.css';

function Tekeningen() {
    useEffect(() => {
        "use strict";

        var items = document.querySelectorAll(".timeline li");

        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    items[i].classList.add("in-view");
                }
            }
        }

        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);

        return () => {
            window.removeEventListener("load", callbackFunc);
            window.removeEventListener("resize", callbackFunc);
            window.removeEventListener("scroll", callbackFunc);
        };
    }, []);

    const showImage = (imageSrc) => {
        // Check if the device is not a mobile device
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (isMobile) {
            return;
        }

        var modal = document.createElement('div');
        modal.classList.add('modal');

        var closeButton = document.createElement('button');
        closeButton.innerHTML = 'Sluiten';
        closeButton.classList.add('modal-close');
        closeButton.addEventListener('click', function () {
            modal.remove();
        });

        var image = document.createElement('img');
        image.src = imageSrc;
        image.classList.add('modal-img');

        modal.appendChild(closeButton);
        modal.appendChild(image);

        document.body.appendChild(modal);

        modal.addEventListener('click', function (event) {
            if (event.target === modal) {
                modal.remove();
            }
        });
    };

    return (
        <>
            <div id="projects">
                <h1 className="title-white">Tekeningen</h1>
                <div className="timeline">
                    <ul>
                        <li>
                            <div onClick={() => showImage(tekening1)}>
                                <img src={tekening1} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening2)}>
                                <img src={tekening2} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening3)}>
                                <img src={tekening3} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening4)}>
                                <img src={tekening4} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening5)}>
                                <img src={tekening5} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening6)}>
                                <img src={tekening6} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening7)}>
                                <img src={tekening7} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening8)}>
                                <img src={tekening8} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening9)}>
                                <img src={tekening9} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => showImage(tekening10)}>
                                <img src={tekening10} width="350px" alt='test' />
                                <span className="overlay">Open de tekening..</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Tekeningen;
