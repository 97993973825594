import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Overmij from "./views/Overmij";
import Contact from "./views/Contact";
import Tekeningen from "./views/Tekeningen";
import Profiel from "./views/Profiel";
import Footer from "./components/Footer";


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Profiel/>
        <Tekeningen />
        <Overmij />
        <Contact />
        <Footer />
        <Routes>
          <Route path="/" exact />
          {/* <Route path="/" element={<Home/>} /> */}
        </Routes>

      </Router>
    </>
  );
}

export default App;
