import React, { Component } from 'react'

export default class Overmij extends Component {
    render() {
        return (
            <>
                <div id="about">
                    <h1 class="title-black">Over Mij</h1>
                    <div class="section-container">
                        <div class="about-details-container">
                            <p class="about-text">
                                "Mijn schilderijen zijn een vertaling van mijn gevoel, dat getriggerd wordt door alles om me heen. Door wat
                                ik lees in een krant, boek, pamflet of gedicht. Door wat ik zie in het nieuws, in mijn omgeving of in de
                                wereld. Door wat ik meemaak in het dagelijkse leven. Door de mensen die ik ontmoet en de persoonlijke
                                verhalen die zij met mij delen.
                                Het kunnen mooie dingen zijn, maar ook afzichtelijke. De wereld is niet alleen mooi. Het is David
                                Attenborough én Poetin, nectar én stront.
                                <br /> <br />
                                Ik werk graag op mezelf. Geconcentreerd. Ik herbeleef of herkauw het gevoel, die uitspraak, die beleving en
                                kneed hieruit met inkt een tekening. Die fase heeft een begin en een natuurlijk einde, Zoals bijvoorbeeld
                                een expositie, waardoor er voor mij een nieuwe persoonlijke standaard ontstaat.
                                Het raakt me als mijn werk u raakt. Dan heb ik het goed gedaan. De tekeningen zijn de visuele sporen van een
                                zelfgesprek die resulteren in stukken waar ik me aan optrek of tegen afzet. Dat wil ik graag delen.
                                Soms volgt er onbegrip, soms een aanvullende invalshoek en soms bevestiging. Maar altijd is er het vuur van
                                meer. Meer mooi, meer lelijk, meer gesprek.
                                <br /> <br />
                                Wat me bezighoudt, wil ik graag doorgronden, maar of dat lukt, weet ik soms niet. Ik twijfel en zoek. En
                                intussen zijn de visuele sporen voor u. Enerzijds om u aan te laven, anderzijds om u onderhuids te
                                veraangenamen of met u mee te reizen.
                                Ik speel een serieus spel van licht en donker, van droog en nat, van hard en zacht, van zoekend en
                                trefzeker. Hierbij gebruik ik diverse technieken, die ik steeds weer uitbreid ̵ met zoeken en vinden, met
                                compromissen en samenvoegingen. Zoals ooit de letters van het alfabet zijn gevormd: begonnen bij A en
                                eindigend bij Z, zoek ik nu met mijn zwart naar de 27e letter van het alfabet, net zolang totdat ik kan
                                uitdrukken wat niet geschreven kan worden."
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

