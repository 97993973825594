import React from 'react'
import { MDBFooter } from 'mdb-react-ui-kit';
import '../styles/footer.css';

function Footer() {
  return (
    <>
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <div className='footer-section'>
          Website gemaakt door: Rens van Gemert
        </div>
        <div className='footer-section'>
          © 2024 Copyright: Hans Snelders
        </div>

      </MDBFooter>
    </>
  )
}

export default Footer
