import React, { Component } from 'react'
import profilePic from '../assets/profile/hans.jpeg';

export default class Profiel extends Component {
    render() {
        return (
            <>
                <div id="profile">
                        <img src={profilePic} class="profile-pic" alt="Hans Snelders" />
                    <div class="section__text">
                        <div className="profile-text">
                            <h1 class="title">Hans Snelders</h1>
                            <p className='current-maintainance-text'>
                                Welkom op mijn website! <br/>
                                Kijk rustig rond, zou ik zeggen. <br/> <br/>
                                Op dit moment wordt er nog aan deze site gewerkt. Heb je op-/aanmerkingen?
                                Laat het me dan vooral weten, dan kan ik deze meenemen in het uiteindelijke ontwerp. <br/> <br/>
                                Heb je vragen over mijn werk of wil je graag met me in contact komen?
                                Ook dan hoor ik graag van je: hans.snelders@ziggo.nl.</p>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
