import React, { Component } from 'react'
import email from '../assets/socials/email.png';
import linkedIn from '../assets/socials/linkedin.png';

export default class Contact extends Component {
    render() {
        return (
            <>
                <div id="contact">
                    <h1 class="title-black">Neem contact op</h1>
                    <div class="contact-info-upper-container">
                        <div class="contact-info-container">
                            <img src={email} alt="Email icon" class="icon email-icon" />
                            <p><a href="mailto:hans.snelders@ziggo.nl">hans.snelders@ziggo.nl</a></p>
                        </div>
                        <div class="contact-info-container">
                            <img src={linkedIn} alt="LinkedIn icon" class="icon contact-icon" />
                            <p><a href="https://www.linkedin.com/in/hans-snelders-59839a2b/" rel="noopener noreferrer" target="_blank">Hans Snelders</a></p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
